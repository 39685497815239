import React from "react"
import Layout from "../components/layout"
import { graphql, Link } from "gatsby"
import { Container } from "reactstrap"
import SEO from "../components/seo"

import leaftop from "@images/home/leaf-top.png"
import leafbottom from "@images/home/leaf-bottom.png"
const slugify = require("slugify")

const Blogs = ({ data, location }) => {
  console.log(data)
  return (
    <Layout location={location}>
      <SEO title="Blog" description={data.site.siteMetadata.description} />

      <div id="blog" className="page-content blog-page blog-section">
        <Container>
          <div className="row mb-4">
            <div className="col-12 mb-5">
              <div className="page-content-title">
                <h1 className="section-title text-d-cyan f-700">
                  <span className="title-underline">Blog </span>
                </h1>
              </div>
              <div className="blog-section blog-page">
                <Container>
                  <div className="row justify-content-between">
                    <div className="col-md-7">
                      {data.allSanityPost.edges.map(blog => (
                        <div className="blog-item" key={blog.node.slug.current}>
                          <div className="row">
                            <div className="col-lg-5 px-lg-0">
                              <Link to={blog.node.slug.current}>
                                <div
                                  className="blog-image h-100 w-100"
                                  style={{
                                    backgroundImage: `url(${
                                      blog.node.mainImage !== null
                                        ? blog.node.mainImage.asset
                                            .gatsbyImageData.images.fallback.src
                                        : "https://source.unsplash.com/user/joshhild/500x500"
                                    })`,
                                  }}
                                />
                              </Link>
                            </div>
                            <div className="col-lg-7 pl-lg-0">
                              <div className="blog-text">
                                <span className="small font-italic text-d-cyan f-600">
                                  <i className="fa fa-calendar-o pr-1" />
                                  {blog.node.publishedAt}
                                </span>
                                <Link to={blog.node.slug.current}>
                                  <h4 className="text-d-green pb-0 my-2">
                                    {blog.node.title}
                                  </h4>
                                </Link>
                                <p className="text-dark">
                                  By: {""}
                                  {blog.node.authors.length
                                    ? blog.node.authors[0].person.name
                                    : ""}
                                </p>
                                {/* <div className="small">
                                  {blog &&
                                  blog.node &&
                                  blog.node.categories &&
                                  blog.node.categories.length !== 0
                                    ? blog.node.categories.map(ct => (
                                        <span key={ct.title}>
                                          <Link to="/">
                                            {ct.title.toUpperCase() + ", "}
                                          </Link>
                                        </span>
                                      ))
                                    : null}
                                </div> */}
                                <p
                                  className="mb-0"
                                  style={{
                                    fontSize: "20px",
                                  }}
                                >
                                  {blog.node.excerpt}
                                </p>
                                <Link to={blog.node.slug.current}>
                                  <p className="text-d-cyan f-600">Read More</p>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="col-md-4 mb-4">
                      <div className="side-content tags">
                        <h5 className="arch-tags text-d-green pb-2">
                          Archives
                        </h5>
                        <ul className="list-unstyled">
                          {data.allSanityArchive.group.map(cat => (
                            <li key={cat.fieldValue} className="mr-1 mb-2">
                              <Link
                                to={`/blog/${slugify(
                                  cat.fieldValue.toLowerCase()
                                )}`}
                                className="text-d-cyan mr-2 f-400"
                              >
                                {cat.fieldValue}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className="side-content tags">
                        <h5 className="arch-tags text-d-green">Tags</h5>
                        <ul className="list-unstyled mx-2">
                          {data.allSanityPost.group.map(cat => (
                            <li key={cat.fieldValue} className="mr-1 mb-2">
                              <Link
                                to={slugify(cat.fieldValue.toLowerCase())}
                                className="text-d-cyan f-400"
                              >
                                {cat.fieldValue.toUpperCase()}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </Container>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </Layout>
  )
}

export default Blogs

export const blogQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
    allSanityPost(sort: { order: DESC, fields: publishedAt }) {
      edges {
        node {
          id
          title
          publishedAt(formatString: "MMMM DD, YYYY")
          mainImage {
            asset {
              gatsbyImageData(
                fit: FILL
                placeholder: BLURRED
                sizes: ""
                layout: CONSTRAINED
              )
            }
          }
          authors {
            person {
              name
            }
          }
          excerpt
          slug {
            current
          }
          categories {
            title
          }
          archive {
            title
          }
        }
      }
      group(field: categories___title) {
        fieldValue
      }
    }
    allSanityArchive {
      group(field: title) {
        fieldValue
      }
    }
  }
`
